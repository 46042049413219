
import { ref, getCurrentInstance } from "vue"
import { loginUser, loginValidation } from "@utils/LoginValidator.ts"
import { ElMessage } from 'element-plus'



export default{
    name: "Login",
    components : {},
    data(){
        return {
            loginUser: loginUser,
            loginValidation: loginValidation
        }
    },
    setup(){
        // @ts-ignore
        const { proxy } = getCurrentInstance()

        // 触发登录方法
        const handleLogin = ( formName : string ) => {
            proxy.$refs[ formName ].validate( async ( valid: boolean ) => {
                if ( valid ) {
                    //let credentials = { uid: loginUser.value.uid, password : loginUser.value.password }
                    let credentials = { username : loginUser.value.username, password : loginUser.value.password }

                    await proxy.$post( '/login', credentials )
                        .then( res => {
                            ElMessage( { message: res.message, type: 'success', showClose : true } )
                            // save state
                            proxy.$store.dispatch( 'login', res.data )
                        } )
                }else{
                    ElMessage( { message: 'Validation errors', type: 'error', showClose : true } )
                }
            })
        }
        return { handleLogin }
    },
}
